import React from 'react'
import './Footer.css'

import Logo from'../../assets/logo-01.png'
import ig from'../../assets/instagram.png'
import linkidn from'../../assets/linkedin.png'
import github from'../../assets/github.png'
const Footer = () => {
  return (
    <div className="footer">
      <div className="first">
<div className="logo">
        <img src={Logo} className='Logo'/></div>
        
<p>No 1 fitness Clun in the town to hlp you shape Your ideal body</p>
<div className="social">
<img src={ig}/>
  <img src={linkidn}/>
  <img src={github}/>
      </div>
</div>

<div className='Test'>
  <h1>Healthy Living</h1>
<ul>
<li>Fitness</li>
<li>Health</li>
<li>Nutrition</li>
<li>Health Recipes</li>
</ul>
</div>
<div className='Test'>
  <h1>Service</h1>
<ul>
<li>Yoga Class</li>
<li>Body Building</li>
<li>Strong Muscle</li>
<li>Sixpack Body</li>
</ul>
</div>

<div className='Test'>
  <h1>Programs</h1>
<ul>
<li>Workout Video</li>
<li>Custom Workol</li>
<li>Experts</li>
</ul>
</div>

    </div>
  )
}

export default Footer