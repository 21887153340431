import React from 'react'
import './Programs.css'
import { programsData } from '../../data/programsData'
import RightArrow from '../../assets/rightArrow.png'
const Programs = () => {
  return (
    <div className="programs" id="programs">
        <div className="programsheader">
           
           <ul><li><a>Our Program</a></li></ul>
           <p>BUild Your <span>Best Body</span></p>
        </div>
   
    <div className="programs-categories">
{programsData.map((program)=> (
    <div className='category'>
    {program.image}
    <span>{program.heading}</span>
    <span>{program.details}</span>
    <div className="arrowright"><img src={RightArrow} /></div>
    </div>

))}
    </div> </div>
  )
}

export default Programs