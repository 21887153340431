import React from 'react'
import './Service.css'
import img from '../../assets/111.png'

const Service = () => {
  return (
    <div className="service">
         <div className="serviceheader">
           
           <ul><li><a>Services</a></li></ul>
           <h1>BUild Your <span>Best Body</span></h1>
           <p>Libero Rutrum et molestie nunc sem rhoncus pallentesque fermentum porttitor tellus tincidunt.</p>
        </div>
        
        <div className="Serv">

        <div className="image"> 
        <div className="task">
        <i class="ri-check-double-fill"></i>
        <span>Porttitor ultrices ut</span>
        </div>
        <div className="task">
        <i class="ri-check-double-fill"></i>
        <span>Amet lorem vel etiam sed </span>
        </div>
        <div className="task">
        <i class="ri-check-double-fill"></i>
        <span>Aenean interdum in mi porta</span>
        </div>
        <button className='btnn'>Learn More</button>
        </div>
        
        <div className="image"> 
        <div className="task">
        <i class="ri-check-double-fill"></i>
        <span>Nec facilisi turpis ultrices</span>
        </div>
        <div className="task">
        <i class="ri-check-double-fill"></i>
        <span>Vitae volutpat ultricies</span>
        </div>
        <div className="task">
        <i class="ri-check-double-fill"></i>
        <span>Orci est gavida faucibus</span>
        </div>
        </div>
       
        <img src={img} className="imaaa"/>
  </div> 
    </div>
  )
}

export default Service