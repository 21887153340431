
import React from 'react'
import './hero.css'
import Header from '../Header/Header'
import { motion } from 'framer-motion'
import Numbercounter from 'number-counter';

import Main from'../../assets/2323.png'
const hero = () => {
  const transition = {type: 'spring', duration: 3}
  return (
    <div className='hero'>
      <div className="blur hero-blur"></div>
   <Header />
   <div className="all">
   <div className="MainLeft">
<div className="title">
  <h1><span>Fitness</span> is not a <br/> destination it <br/>is <span>away of life.</span> </h1>
<p>In Here we willhelp you to shape and build your ideal body and live up your life to the fullest</p>
</div>
<div className="emailbar">
  <input placeholder=' Enter Your Name Here'/>
    <button  className='btn'>
    Get Started <i class="ri-arrow-right-s-line"></i></button>
</div>
{/*Figures */}
<div className="fugures">
 <div>
  <span><Numbercounter end={5000} start={2500} delay={1} preFix="+"/>
    </span>
  <span>Happy Clients</span>
 </div>
 <div>
  <span><Numbercounter end={26} start={0} delay={1} preFix="+"/>
    </span>
  <span>Years Experience</span>
 </div>
 <div>
  <span>
  <Numbercounter end={50} start={10} delay={1} preFix="+"/>
    
  </span>
  <span>Expert Trainers</span>
 </div>
</div>
   </div>
   <div className="mainright">
      <div className="blur hero-blur2"></div>
   <img src={Main} className='Image'/>
   </div>
    </div>
    </div>
  )
}

export default hero