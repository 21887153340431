import React from 'react'
import './Plan.css'
import {plansData} from '../../data/plansData'
import whitechek from '../../assets/whiteTick.png'

const Plan = () => {
  return (
    <div className="plans">
<div className="Planheader">

           <ul><li><a>Pricing</a></li></ul>
           <p>Our Special <span>Plan</span></p>
        </div>
<div className="maiin">
        {plansData.map((plan, i)=>(
        <div className="plan" key={i}>
            {plan.icon}
            <span>{plan.name}</span>
            <span>$ {plan.price}</span>

            <div className="features">
                {plan.features.map((feature, i)=>(
                    <div className="feature">
                        <img src={whitechek} alt=""/>
                        <span key ={i}>{feature}</span>
                    </div>
                ))}
            </div>

            
            <button className="btn1">Choose</button>
        </div>
        ))}
    </div>
    <div className="blur hero-blur3"></div>
    <div className="blur hero-blur4"></div>
    </div>
  )
}

export default Plan