import './App.css';
import Hero from './component/Hero/Hero';
import Programs from './component/Programs/Programs';
import Service from './component/Service/Service';
import Plan from './component/Plan/Plan';
import Footer from './component/Footer/Footer';
function App() {
  return (
    <div className="App">
     <Hero />
     <Programs />
     <Service />
     <Plan />
     <Footer />
     </div>
  );
}

export default App;
