
import React from 'react'
import './header.css';
import Logo from'../../assets/logo-01.png'
import 'remixicon/fonts/remixicon.css'

const header = () => {
  return (
    <div className="constraint">
      <div className="left">
      <div className="logo">
        <img src={Logo} className='Logo'/></div>
     
        <div class="container">
        <ul>
  <li><a href="">Home</a></li>
  <li><a href="">Classes</a></li>
  <li><a href="">Pricing</a></li>
  <li><a href="">About</a></li>
</ul>
          {/*
        <a href="#"><span>Home</span></a>
        <a href="#"><span>Classes</span></a>
        <a href="#"><span>Pricing</span></a>
  <a href="#"><span>About</span></a>*/}
    </div>
        </div>
        <div className="right">
        <div className='icons'>
          <i class="ri-search-line"></i><span>&#124;</span> Eng <i className="ri-arrow-down-s-line"></i>
          </div>
    
    <button className='btn'>Sign Up</button>
    </div>
    </div>
  )
}

export default header